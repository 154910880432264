<template>
  <div>
    <img class="fixed bottom-5 right-5" src="/img/logo2.png">
    <div class="flex align-center justify-center h-100vh background-image">
      <div class="flex justify-center flex-col">
        <div>
          <img class="mx-auto" src="/img/connect-logo.png">
        </div>
        <div class="text-center relative top-10">
          <a v-if="online"
             class="bg-011B36 p-2 rounded-lg text-FFBBAC hover:bg-FFBBAC hover:text-011B36 transition duration-200 ease-in-out"
             href="https://profel.sharepoint.com/sites/intranet-nl">
            Klik om te connecteren
          </a>
          <a v-else
             class="bg-011B36 p-2 rounded-lg text-FFBBAC hover:bg-FFBBAC hover:text-011B36 transition duration-200 ease-in-out"
             href="">
            Geen verbinding, probeer opnieuw
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  data() {
    return {
      online: navigator.onLine,
      showBackOnline: false,
    }
  },

  methods: {
    updateOnlineStatus(e) {
      const { type } = e;
      this.online = type === 'online';
    },

  },
  computed:{

  },

}
</script>

<style>
.background-image {
  background-image: url("/img/background-2.png");
  background-color: #fdede8;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>