<template>
  <div class="pwa-prompt" @click="tooltip_active = !tooltip_active" v-if="needsToSeePrompt && tooltip_active">
    <div class="w-full p-4">
      <p class="leading-8">Installeer deze app door op <span class="inline-block mx-1"><img
          :src="require('@/assets/img/icons/ios-action.png')" width="16"></span> te klikken <br>
        en selecteer
        dan "Zet op beginscherm"</p>
    </div>
  </div>
</template>

<script>
import {isMobile} from "@/helpers/utils.js";

export default {
  data() {
    return {
      standalone: ('standalone' in window.navigator) && (window.navigator.standalone),
      tooltip_active: true
    }
  },
  computed: {
    needsToSeePrompt() {
      if (this.standalone) {
        return false;
      }

      let has_seen_tooltip = localStorage.getItem('tooltip_shown_ts');
      if (has_seen_tooltip) {
        return false;
      }

      localStorage.setItem('tooltip_shown_ts', new Date());

      return isMobile.iOS();
    },
  },
}
</script>

<style scoped>
.pwa-prompt {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  @apply bg-profel-green
}
</style>